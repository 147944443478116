import React from "react";
import "./OtherLinks.css"; // Make sure to create and import a CSS file for the styles
import imagePath from "../../assets/Picture.png";
import Nepal from "../../assets/Nepal.png";

const OtherLinks = () => {
  return (
    <div className="container-links">
      <div className="image-container">
        <img src={Nepal} alt="Team" />
      </div>
      <div className="text-container">
        <h1>About Nepal</h1>
        <p>
          Nepal is a mountainous and a land locked country situated between two
          giant neighbours, China to the North and India to the South, East
          andWest. Nepal boasts of having the highest peak of the world, Mount
          Everest and visitors from all over the world visit the country to
          scale Mount Everest and to see the beautiful panorama, snow-capped
          mountains and gorgeous historic monuments.
        </p>
        <p>
          Moreover, it should also be noted that Nepal is the 2nd richest
          coun-try in the world in water resources with a substantial number of
          rivers & streams owing towards the South from the high Himalayas. And
          the Tilicho Lake situated at the highest land of the world is also in
          Nepal. So the people who visits Nepal, denitely gets optimal
          gratication from its natural gorgeous scenery. It has highly diverse
          and rich geography, culture and religions.
        </p>
        <ul>
          <li>Area 1,47,181 Sq. Km.</li>

          <li>Capital Kathmandu Location Between China & India</li>
          <li>
            Geo Location: Latitude 26° 21’- 30° 26’N Longitude 80° 3’- 88°12’
            Population 30 M (Approx)
          </li>
          <li>
            Political System Federal Democratic Republic System National
            Language Nepali
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OtherLinks;
