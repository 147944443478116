import React from "react";
import team from "../../assets/team-image.png";
const teamContent = {
  title: "Message from Our Team",
  description: `We pride ourselves as an emerging and trustworthy manpower agency, operating foreign employment professions in Nepal. We at Baibhav Overseas Pvt Ltd are equipped with well-experienced and passionate individuals who really understand what it takes to go over and beyond for our clients overseas.

Our brand is dedicated to our clients by ensuring to provide fully dedicated professionals with ample experience. Thank you to all our clients for the continued support whilst considering us as a business partner. We encourage you to get in touch, as we are available to support business in between forever.

Regards,
Baibhav Overseas`,
};
const Team = () => {
  return (
    <>
      {" "}
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        {" "}
        {teamContent.title}
      </h1>
      {/* <div className="about-div"> */}
      <div className="image-area">
        <img
          className="about-img"
          style={{ width: "600px", height: "auto" }}
          st
          img
          src={team}
          alt="team-image"
        />
        {/* </div> */}
        <div className="about-us">
          <p>{teamContent.description}</p>
        </div>
      </div>
    </>
  );
};

export default Team;
