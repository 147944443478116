import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Textarea,
  Grid,
  Center,
  VStack,
  HStack,
} from "@chakra-ui/react";

export const Contact = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted!");
  };

  return (
    <>
      <h1
        style={{
          textAlign: "center",
          fontFamily: "Arial, sans-serif",
          padding: "20px",
          marginTop: "20px",
          fontSize: "2.5rem",
        }}
      >
        Contact Us
      </h1>
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={8}
        p={{ base: 4, md: 20 }}
      >
        <VStack spacing={8} align="stretch" maxW="1000px" w="100%">
          <Center>
            <form onSubmit={handleSubmit}>
              <VStack spacing={8} align="stretch" maxW="900px" w="100%">
                <HStack
                  spacing={8}
                  w="100%"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <FormControl id="firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Enter your first name"
                      size="lg"
                    />
                  </FormControl>

                  <FormControl id="lastName" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Enter your last name"
                      size="lg"
                    />
                  </FormControl>
                </HStack>

                <FormControl id="email" isRequired>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type="email"
                    placeholder="Enter your email"
                    size="lg"
                  />
                  <FormHelperText>We'll never share your email.</FormHelperText>
                </FormControl>

                <FormControl id="password" isRequired>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Enter your password"
                    size="lg"
                  />
                </FormControl>

                <FormControl id="message">
                  <FormLabel>Message</FormLabel>
                  <Textarea placeholder="Enter your message" size="lg" />
                </FormControl>

                <Button type="submit" colorScheme="blue" size="lg" mt={8}>
                  Submit
                </Button>
              </VStack>
            </form>
          </Center>
        </VStack>

        <Center>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.364422265816!2d-0.12478078422974548!3d51.50816927633844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ce8a2f8e3d%3A0x16c6e9f883c4a6c6!2sBig%20Ben!5e0!3m2!1sen!2suk!4v1624165937405!5m2!1sen!2suk"
            width="100%"
            height="400"
            style={{ border: 0, borderRadius: "8px" }}
            allowFullScreen=""
            loading="lazy"
            title="Google Maps"
          ></iframe>
        </Center>
      </Grid>
    </>
  );
};
