import React from "react";
import "./CompanyReg.css"; // Assuming you have a CSS file for styling
import Reg1 from "../../assets/Reg1.png";
import Reg2 from "../../assets/Reg2.png";
import Reg3 from "../../assets/Reg3.png";
import Reg4 from "../../assets/Reg4.png";

const CompanyReg = () => {
  // Array of image paths
  const images = [Reg1, Reg2, Reg3, Reg4];

  return (
    <div className="company-reg-container">
      {images.map((image, index) => (
        <img key={index} src={image} alt={`Reg${index + 1}`} />
      ))}
    </div>
  );
};

export default CompanyReg;
