import React from "react";
import Slider from "react-slick";
import ReactStars from "react-rating-stars-component";
import { Heading } from "@chakra-ui/react";
import "./Testimonial.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Clientdata = [
  {
    name: "John Smith",
    imgSrc: "/image/client1.jpeg",
    review:
      "Baibhav Overseas Pvt. Ltd. provided us with exceptional manpower sourcing services. Their dedication to quality, honesty, and reliability ensured that we received skilled and efficient workers for our construction projects abroad. Please do check them out.",
    rating: 5,
  },
  {
    name: "Emily Johnson",
    imgSrc: "/image/client2.jpeg",
    review:
      "We utilized Baibhav Overseas Pvt. Ltd.'s recruitment services for our hospitality sector staffing needs. They meticulously matched our requirements with suitable candidates, resulting in a motivated and competent workforce. Their prompt service and attention to detail were impressive.",
    rating: 4,
  },
  {
    name: "Michael Davis",
    imgSrc: "/image/client3.jpeg",
    review:
      "Baibhav Overseas Pvt. Ltd. played a crucial role in sourcing skilled IT professionals for our overseas tech projects. Their thorough vetting process and commitment to client satisfaction ensured that we had the right talent to drive our projects forward. We look forward to continuing our partnership.",
    rating: 5,
  },
  {
    name: "Sophia Wilson",
    imgSrc: "/image/client4.jpeg",
    review:
      "The logistical support provided by Baibhav Overseas Pvt. Ltd. was instrumental in managing our supply chain across international borders. Their expertise in navigating customs regulations and efficient shipping solutions helped us maintain smooth operations and meet customer demands effectively.",
    rating: 4,
  },
];

const Reviews = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Adjust number of slides shown at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Adjust settings for smaller screens if needed
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="bgHeading">
      <div className="heading">
        <Heading as="h1" size="2xl">
          What our client says.
        </Heading>
      </div>
      <div className="client-slider">
        <Slider {...settings}>
          {Clientdata.map((client, index) => (
            <div key={index} className="testimonial-item">
              <div className="testimonial-box">
                <p>{client.review}</p>
                <ReactStars
                  count={5}
                  value={client.rating}
                  size={24}
                  edit={false}
                  activeColor="#ffd700"
                />
                <h3>{client.name}</h3>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Reviews;
