import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from "./component/Navbar/Navbar";
import Footer from "./component/Footer/Footer";
import { About } from "./Pages/About/About";
import { WhyUs } from "./Pages/Why Us/WhyUs";
import { Home } from "./Pages/Home/Home";
import Services from "./Pages/Services/Services";
import Reviews from "./Pages/Testimonial/Testimonial";
import { Contact } from "./Pages/Contact/Contact";
import WelcomeSection from "./Pages/WelcomeSection/WelcomeSection";
import Chairman from "./Pages/About/Chairman";
import Team from "./Pages/About/Team";
import CompanyReg from "./Pages/CompanyReg/CompanyReg";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about/about-us" element={<About />} />
        <Route path="/about/chairman" element={<Chairman />} />
        <Route path="/about/team" element={<Team />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/welcome-section" element={<WelcomeSection />} />
        <Route path="/company-registration" element={<CompanyReg />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
