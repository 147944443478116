import React from "react";
import Slider from "react-slick";
import { Heading } from "@chakra-ui/react";
import "./Services.css";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const products = [
  {
    title: "Kuwait",
    imgSrc: "/image/client1.png",
  },
  {
    title: "BELT LOADERS",
    imgSrc: "/image/client6.png",
  },
  {
    title: "PAX STEPS",
    imgSrc: "/image/client3.png",
  },
  {
    title: "LAVATORY AND WATER TRUCKS",
    imgSrc: "image/client4.png",

    link: "/product/lavatory",
  },
  {
    title: "COBUS Ramp Bus",
    imgSrc: "image/client5.png",
  },
];

const Services = () => {
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Display two products at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Adjust settings for smaller screens if needed
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="bgHeadinger">
      <div className="heading">
        <h1 as="h1" size="20px">
          Our Clients
        </h1>
      </div>
      <div className="products-slider">
        <Slider {...settings}>
          {products.map((product, index) => (
            <div key={index} className="product-item">
              <div className="product-item-box">
                <img src={product.imgSrc} className="product-image" />

                <br />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Services;
