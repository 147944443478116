import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ImgBg from "../../assets/bgimgg.png";
import "./Home.css";
import Reviews from "../Testimonial/Testimonial";
import WelcomeSection from "../WelcomeSection/WelcomeSection";
import OtherLinks from "../Other Links/OtherLinks";
import Services from "../Services/Services";

export const Home = () => {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate("/about/about-us");
  };

  return (
    <>
      <div className="home">
        <div className="bgImage">
          <img src={ImgBg} alt="Background" />
        </div>
        <div className="content">
          <h1>Welcome to Baibhav Overseas</h1>
          <p>Your journey to knowledge starts here.</p>
          <button className="cta-button" onClick={handleGetStartedClick}>
            Get Started
          </button>
        </div>
      </div>
      <div>
        <div style={{ marginTop: "50px" }}>
          <WelcomeSection />
          <Services />
        </div>
        <div style={{ marginTop: "50px" }}>
          <OtherLinks />
        </div>
        <Reviews />
      </div>
    </>
  );
};
