import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/logo.png";
import { CodeIcon, HamburgetMenuClose, HamburgetMenuOpen } from "./Icon";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <div style={{ backgroundColor: "rgb(245,245,247" }}>
      <nav className="navbar">
        <div className="nav-container">
          <Link exact to="/" className="nav-logo">
            {/* <span>CodeBucks</span> */}
            {/* <i className="fas fa-code"></i> */}
            <span className="icon">
              <img src={logo} alt="logo" />
            </span>
          </Link>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                exact
                to="/"
                activeClassName="active"
                className="nav-Links"
                onClick={handleClick}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                exact
                to="/about/about-us"
                activeClassName="active"
                className="nav-Links"
              >
                About
              </Link>
              <div className="dropdown-content">
                <Link
                  className="dropdown-item"
                  to="/about/chairman"
                  onClick={handleClick}
                >
                  Message from the Chairman
                </Link>
                <Link
                  className="dropdown-item"
                  to="/about/team"
                  onClick={handleClick}
                >
                  Message from the team
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link
                exact
                to="/why-us"
                activeClassName="active"
                className="nav-Links"
                onClick={handleClick}
              >
                Why Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                exact
                to="/services"
                activeClassName="active"
                className="nav-Links"
                onClick={handleClick}
              >
                Our Clients
              </Link>
            </li>
            <li className="nav-item">
              <Link
                exact
                to="/contact"
                activeClassName="active"
                className="nav-Links"
                onClick={handleClick}
              >
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link exact activeClassName="active" className="nav-Links">
                Legal Documents
              </Link>
              <div className="dropdown-content">
                <Link
                  className="dropdown-item"
                  to="/company-registration"
                  onClick={handleClick}
                >
                  Company License
                </Link>
              </div>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* <i className={click ? "fas fa-times" : "fas fa-bars"}></i> */}

            {click ? (
              <span className="icon">
                <HamburgetMenuClose />{" "}
              </span>
            ) : (
              <span className="icon">
                <HamburgetMenuOpen />
              </span>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
