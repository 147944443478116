import React from "react";
import chairman from "../../assets/chairman.png";

const chairmanContent = {
  title: "Message from the Chairman",
  description: `It is our great pleasure to introduce with our reputed company. We Baibhav Overseas Pvt. Ltd., one of the leading Nepalese Human Resources &amp; Manpower Recruitment Consultant approved by Government of Nepal &amp; registered with Government of Nepal, Ministry of Labor under License number 373/059/060. We are well organized and well staffed and all our customers and clients are highly satisfied with our reliable and timely services. Within a short period, we have successfully completed recruitment of thousands of skilled, semi-skilled and unskilled work force for a number of overseas employers.

Being land of Gurkhas, Nepalese workers are well known for their hard work, loyalty, discipline and high sense of responsibilities. They are equally competent, very honest, peaceful and very hospitable people. Our workers are reputed for hard work obedient, dutiful and most loyal to their employers. Comparatively Nepalese workers best and economically advantageous to the employer.

We undertake complete responsibility to recruit any category of personnel from Nepal on behalf of any legally authorized and genuine overseas employment regulations on the Government of Nepal and that of Government of the country of employment. With the perennial co-operation and support from you and your established company, we will always be able to preserve our status of being the top Manpower Consultants of Nepal and we like to play a significant role in our country’s economic development by improving economic condition.
If you are looking reliable services for recruitment of workers for your established company, we request you to try with us. We promise you that we would do our best from our professional management to provide you reliable &amp; prompt services.

We heartily welcome your kind co-operations and generous recruitment offers. Hope to get your positive reply by Fax or mail.
Thanks & best regards, Your Sincerely
Rastra Bhushan Chairman`,
};

const Chairman = () => {
  return (
    <>
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {chairmanContent.title}
      </h1>
      <div className="about-div">
        <div className="image-area">
          <img
            className="about-img"
            style={{ width: "600px", height: "auto" }}
            st
            img
            src={chairman}
            alt="chairman"
          />
        </div>
        <div className="about-us">
          <p>{chairmanContent.description}</p>
        </div>
      </div>
    </>
  );
};

export default Chairman;
