import React from "react";
import "./WelcomeSection.css";
import integrityIcon from "../../assets/icon1.png"; // Replace with actual path
import customerCentricityIcon from "../../assets/icon2.png"; // Replace with actual path
import accountabilityIcon from "../../assets/icon3.png"; // Replace with actual path
import innovationIcon from "../../assets/icon4.png"; // Replace with actual path
// import welcomeImage from "../../assets/welcomeImage.png";

const WelcomeSection = () => {
  return (
    <div className="welcome-section">
      <section>
        <div className="stats">
          <h2>10,000+</h2>
          <p>Registered job seekers</p>
        </div>
        <div className="core-values">
          <div className="value-item">
            <img src={integrityIcon} alt="Integrity" />
            <p>Integrity</p>
          </div>
          <div className="value-item">
            <img src={customerCentricityIcon} alt="Customer Centricity" />
            <p>Customer Centricity</p>
          </div>
          <div className="value-item">
            <img src={accountabilityIcon} alt="Accountability" />
            <p>Accountability</p>
          </div>
          <div className="value-item">
            <img src={innovationIcon} alt="Innovation" />
            <p>Innovation</p>
          </div>
        </div>
      </section>
      <section>
        <div className="welcome-message">
          <div className="welcome-message-content">
            {/* <img src={welcomeImage} alt="Welcome" />{" "} */}
            {/* Replace with the desired image */}
            <div>
              <h3>WELCOME TO BAIBHAV OVERSEAS NEPAL</h3>
              <h1>We Want You. Apply And Work With Us</h1>
              <p>
                Baibhav Overseas Nepal is a multi-functional, multi-faculty,
                cross-industry global leading overseas, based in Nepal.
              </p>
              <p>
                World leading corporations have turned to us, time and again for
                their skilled, qualified and experienced personnel requirements.
              </p>
              <a href="/about/about-us">Learn More ➔</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WelcomeSection;
