import React from "react";
import "./WhyUs.css";
import whyUsImg from "../../assets/whyUsimage.jpg";

const WhyUsContent = {
  title: "Why Us",
  description: `Quality, Honesty, Sincerity and Reliability are the commitment of the Baibhav Overseas Pvt. Ltd. Yes obviously our first preference to provide you quality manpower in time and as your convenience. We are committed your satisfaction.,
  The services of our Sincere, Honest, Hardworking and Disciplined candidates will help to boost the business to the employer companies as well, in terms of proven moral and productivity. Baibhav Overseas Pvt. Ltd. has confident that the Nepalese National will fit in and contribute to our client company with best.,
  The region for the provision of Manpower recruiting services to Foreign companies, which will help strengthen the financial condition of the Nation as well as of the candidates.,
  To achieve rapid profitable growth by ensuring that our clients needs are satisfied in an efficient and cost-effective manner.,`,
};

export const WhyUs = () => {
  return (
    <div className="why-us">
      <img className="why-us-img" src={whyUsImg} alt="why us image" />
      <div className="custom-services">
        {/* <h1>{WhyUsContent.title}</h1> */}
        <p>{WhyUsContent.description}</p>
      </div>
    </div>
  );
};
