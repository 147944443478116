import React from "react";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const contactInfo = [
  "Baibhav Overseas Pvt. Ltd",
  "Kathmandu, Nepal",
  "Phone: 00977-1-4106264/65",
  "Email: baibhav.ovs@gmail.com",
  "Address: Binayak Marg, Tinkune, Kathmandu, Nepal",
];

const quickLinks = [
  { name: "Home", path: "/" },
  { name: "About Us", path: "/about/about-us" },
  { name: "Why Us", path: "/why-us" },
  { name: "Our Clients", path: "/services" },
  { name: "Legal Documents", path: "/company-registeration" },
  { name: "Contact Us", path: "/contact" },
];

const socialLinks = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/",
    icon: <FaFacebookF />,
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/dipak-regmi-26043253/",
    icon: <FaLinkedinIn />,
  },
];

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3 className="footerHeader">Contact Us</h3>
          {contactInfo.map((info, index) => (
            <p key={index}>{info}</p>
          ))}
        </div>
        <div className="footer-section">
          <h3 className="footerHeader">Quick Links</h3>
          <ul>
            {quickLinks.map((link, index) => (
              <li key={index}>
                <NavLink
                  to={link.path}
                  activeClassName="active"
                  className="footer-links"
                >
                  {link.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-section">
          <h3 className="footerHeader">Follow Us</h3>
          <ul className="social-media">
            {socialLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.url}
                  className="social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={link.name}
                >
                  {link.icon}
                  <span className="social-text">{link.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} Baibhav Overseas Pvt. Ltd. All
          rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
