import React from "react";
import "./About.css";
import abtImg from "../../assets/abt.jpg";

const aboutUsContent = {
  title: "About Us",
  description: `Baibhav Overseas Pvt. Ltd., is one of the top employment agency in Nepal, duly
accredited by, Department of Labor & Transport Management, Ministry of Nepal.

We are currently engaged and committed in supplying unskilled, skilled and
manpower recruitment solution. When it comes to outsourced staffing requisites,
semi-skilled professionals in Asia, Europe, and in the Gulf Region. It is an organization backed by two decades of result-driven recruitment expertise in off-shore world leading corporations have turned to us, time and again for their skilled, qualified and experienced personnel requirements.

None other can render customized solutions like we can. This explains why the
workers based on the clients’ qualifications and standards. Along with, our clients
As we say it, DEMAND IT, and WE WILL SUPPLY IT! We could absolutely provide.

Our company is managed by professionals who are highly-experienced in Human
Resource Management & Development, International Recruitment, and Labor Relations, so we know exactly how to address the needs, wants, demands, and problems of our overseas clients.
And most definitely, all the company’s great amenities and services are carried on and propelled by young, enthusiastic, and highly competitive workforce who are
always available to attend efficiently to all your requests and needs. Baibhav Overseas will completely guarantee to totally eradicate all the mediocrities and woes you have dealt with before on this kind of business. We are undoubtedly one of the best manpower connections in the Nepal.

You are welcome to reach us via email at info@baibhavovs.com to know how we
can be your one stop manpower recruitment shop
will also be assured of services and processing at par with world-class standards'.`,
};

export const About = () => {
  return (
    <>
      {" "}
      <div className="about-div">
        <h1>{aboutUsContent.title}</h1>
        <div
          className="about-us"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p>{aboutUsContent.description}</p>
        </div>
        {/* <div className="image-area">
          <img className="about-img" src={abtImg} alt="abt us" />
        </div> */}
      </div>
    </>
  );
};
